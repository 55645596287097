@import '../../styles/common';

//
// List
// --------------------------------------------------

.@{ns}list {
  position: relative;
  box-shadow: 0 1px 0 @list-border-color, 0 -1px 0 @list-border-color;
  overflow-x: hidden;
  overflow-y: auto;

  &-bordered {
    border-radius: @border-radius-base;
    box-shadow: 0 0 0 1px @list-border-color;
  }

  &-hover .@{ns}list-item:hover {
    background-color: @list-hover-color;
  }

  &-sortable .@{ns}list-item {
    cursor: move; // fallback
    cursor: grab;

    &-disabled,
    &-disabled:active {
      cursor: not-allowed;
    }
  }
}

// default list item ( axis-y )
.@{ns}list-item {
  position: relative;

  &-bordered {
    padding-left: 20px;
    padding-right: 20px;
  }

  &-lg {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &-md {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  &-sm {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

/* rtl:begin:ignore */
/* stylelint-disable-next-line */ // For RTL Scope
.@{ns}list-item {
  background-color: @list-item-drag-bg-color;
  box-shadow: 0 -1px 0 @list-border-color, 0 1px 0 @list-border-color;

  &-helper {
    position: absolute;
    background-color: @list-item-drag-bg-color;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    width: calc(100% - 2px);
    border-radius: @border-radius-base;
    top: 0;
    left: 0;
    z-index: @zindex-list-helper;
    border: none;
    cursor: move;

    // Increase z-index when modal opened.
    .@{ns}modal-open & {
      z-index: @zindex-modal + @zindex-list-helper;
    }

    // Increase z-index when drawer opened.
    .@{ns}drawer-open & {
      z-index: @zindex-drawer + @zindex-list-helper;
    }
  }

  &-holder {
    background-color: @list-item-placeholder-bg-color;

    &::after {
      content: '';
      position: absolute;
      top: @list-border-width;
      left: @list-border-width;
      width: calc(100% - 2 * @list-border-width);
      height: calc(100% - 2 * @list-border-width);
      border: @list-border-width @list-item-placeholder-border-color dashed;
    }

    .@{ns}list-item-content {
      opacity: 0;
    }
  }
}

/* rtl:end:ignore */
