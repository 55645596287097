.img-cover() {
  width: 100%;
  height: auto;

  @supports (object-fit: cover) {
    & {
      height: 100%;
      object-fit: cover;
    }
  }
}
