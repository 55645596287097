@import '../../styles/common';
@import '../../Picker/styles/mixin';
@import 'mixin';

@custom-picker-tree-node-padding-vertical: @picker-tree-node-padding-vertical - 2px;
// Tree Picker
// ----------------------

// tree picker name space
@tpns: ~'@{ns}@{tree-picker-prefix}';

.@{tpns} {
  background-color: @picker-tree-bg;
  max-height: 360px;
  height: 100%;
  overflow-y: auto;

  /* stylelint-disable-next-line */ // This class name provide by Virtualized.
  .ReactVirtualized__Grid.ReactVirtualized__List:focus {
    outline: none;
  }

  &-drag-node-mover {
    position: absolute;
    top: -1000px;
    color: @B800;
    background: #fff;
    display: inline-block;
    margin: 0;
    padding: @picker-tree-node-padding-vertical @picker-tree-node-padding-horizontal;
    border-radius: 6px;
    box-shadow: 0 1px 8px #00000012;
    z-index: 1060;
    cursor: move;
  }
}

.@{tpns}-node {
  position: relative;
  // Clear host whitespace
  font-size: 0;
  text-align: left;
  margin: 0 0 4px 0;

  &-active > .@{tpns}-node-label > .@{tpns}-node-label-content {
    .picker-item-active;

    color: @picker-tree-node-active-color;
    background-color: @picker-tree-node-active-bg;
  }

  > .@{tpns}-node-label {
    position: relative;
    margin: 0;
    //text gap
    padding-left: @picker-tree-arrow-down-width + 8px;
    display: inline-block;
    cursor: pointer;
    font-size: @picker-tree-node-font-size;
    line-height: @picker-tree-node-line-height;

    > .@{tpns}-node-label-content {
      padding: @custom-picker-tree-node-padding-vertical @picker-tree-node-padding-horizontal
        @custom-picker-tree-node-padding-vertical @picker-tree-arrow-down-gap;
      display: inline-block;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;

      &:hover {
        background-color: @picker-tree-node-hover-bg;
      }

      &:focus {
        background-color: @picker-tree-node-active-bg;
      }
    }
  }

  &-drag-over {
    background-color: @picker-tree-node-hover-bg;
  }

  &-dragging {
    outline: 1px dashed @H500;
  }

  &-drag-over-top {
    &.@{tpns}-node-label-content {
      border-top: 2px solid @picker-tree-node-active-color !important;

      &::after {
        content: '';
        position: absolute;
        top: -2px;
        left: 12px;
        width: 0;
        height: 0;
        border-left: 6px solid @picker-tree-node-active-color;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
      }
    }
  }

  &-drag-over-bottom {
    &.@{tpns}-node-label-content {
      border-bottom: 2px solid @picker-tree-node-active-color !important;

      &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 12px;
        width: 0;
        height: 0;
        border-left: 6px solid @picker-tree-node-active-color;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
      }
    }
  }

  &-drag-disabled {
    &.@{tpns}-node-label-content {
      cursor: not-allowed;
    }
  }

  &-disabled {
    color: @picker-menu-item-disabled-color;
    cursor: @cursor-disabled;

    > .@{tpns}-node-label > .@{tpns}-node-label-content {
      cursor: @cursor-disabled;

      &,
      &:hover {
        background: transparent;
      }
    }
  }

  &-disabled&-active > .@{tpns}-node-label > .@{tpns}-node-label-content {
    &,
    &:hover {
      color: @picker-select-menu-item-selected-disabled-color;
    }
  }

  &:not(&-disabled):focus > .@{tpns}-node-label > .@{tpns}-node-label-content {
    background-color: @picker-tree-node-active-bg;
  }

  &:not(&-disabled) > .@{tpns}-node-label:focus > .@{tpns}-node-label-content {
    background-color: @picker-tree-node-active-bg;
  }
}

// expand icon
.@{tpns}-node-expand-icon-wrapper {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  z-index: 1;

  > .@{tpns}-node-expand-icon {
    display: inline-block;
    padding: @picker-tree-node-padding-vertical 0;
    padding-right: @picker-tree-arrow-down-gap;
    height: 36px;
    font-size: @picker-tree-node-font-size;
    line-height: @picker-tree-node-line-height;
    transform: rotate(-90deg) translateX(-2px)
      /* rtl: rotate(90deg) translateX(-6px) translateY(-8px) */;
    transform-origin: 3.5px 16px;
    margin-left: -2px;
    font-family: @font-family-icon;
    font-style: normal;
    user-select: none;

    &::before {
      content: @picker-tree-arrow-down;
    }

    // expand children tree
    .@{tpns}-open > .@{tpns}-node & {
      transform: rotate(0deg);
      margin-left: 0;
      margin-top: 0;
    }
  }

  > .@{tpns}-node-expanded {
    transform: rotate(0deg);
    margin-left: 0;
    margin-top: 0;
  }

  > .@{tpns}-node-custom-icon {
    .node-item-prepend-icon;
  }
}

// node children
.@{tpns}-node-children {
  > .@{tpns}-children {
    display: none;

    .@{tpns}-open& {
      display: block;
    }
  }
}

.@{ns}picker-menu {
  &.@{tpns}-menu {
    padding-top: @picker-menu-padding;

    .@{ns}picker-search-bar {
      padding-top: 0;
    }
  }

  .@{tpns} {
    padding: 0 @picker-menu-padding @picker-menu-padding 0;

    &-node {
      margin: 0;

      > .@{tpns}-node-label > .@{tpns}-node-label-content {
        .ellipsis;

        display: block;
      }
    }
  }
}

/* rtl:begin:ignore */

[dir='rtl'] .@{tpns}-node-expand-icon-wrapper {
  right: 0;
  padding-right: inherit;
  background: @picker-tree-bg;
}

/* stylelint-disable */
[dir='rtl']
.ReactVirtualized__Grid__innerScrollContainer
.rs-tree-node
> .rs-tree-node-label::before {
  display: none;
}

/* stylelint-enable */

/* rtl:end:ignore */
