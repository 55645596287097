@import 'common';

//
// All keyframes
// --------------------------------------------------

@keyframes shake {
  0%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.7%,
  50%,
  83.3% {
    transform: translate3d(-10px, 0, 0);
  }

  33.3%,
  66.7% {
    transform: translate3d(10px, 0, 0);
  }
}

// Opacity not working in Safari,so remove .
@keyframes slideUpIn {
  0% {
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
  }

  100% {
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}

@keyframes slideDownIn {
  0% {
    transform-origin: 100% 100%;
    transform: scaleY(0.8);
  }

  100% {
    transform-origin: 100% 100%;
    transform: scaleY(1);
  }
}

@keyframes slideLeftIn {
  0% {
    transform-origin: 0% 0%;
    transform: scaleX(0.8);
  }

  100% {
    transform-origin: 0% 0%;
    transform: scaleX(1);
  }
}

@keyframes slideRightIn {
  0% {
    transform-origin: 100% 100%;
    transform: scaleX(0.8);
  }

  100% {
    transform-origin: 100% 100%;
    transform: scaleX(1);
  }
}

@keyframes errorMessageSlideUpIn {
  0% {
    transform: translate3d(0, @error-message-translate-distance, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes errorMessageSlideDownIn {
  0% {
    transform: translate3d(0, -1 * @error-message-translate-distance, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes errorMessageSlideLeftIn {
  0% {
    transform: translate3d(-1 * @error-message-translate-distance, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes errorMessageSlideRightIn {
  0% {
    transform: translate3d(@error-message-translate-distance, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes buttonSpin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

//** Message
//========================
@keyframes messageMoveIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
  }

  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}

@keyframes messageMoveOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
    max-height: 150px;
  }

  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
}

//** Alert
//========================
@keyframes alertMoverIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
  }

  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}

@keyframes alertMoveOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
    max-height: 60px;
  }

  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
}

//** Notification
//========================
@keyframes notificationMoverIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
  }

  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}

@keyframes notificationMoveOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
    max-height: 100px;
  }

  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
}

@keyframes notificationMoveInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes notificationMoveInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

//** Modal
//========================
@keyframes bounceIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes bounceOut {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0.8);
  }
}

//** Drawer
//========================
@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes slideOutLeft {
  from {
    opacity: 1;
    transform: none;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes slideOutRight {
  from {
    opacity: 1;
    transform: none;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slideInTop {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes slideOutTop {
  from {
    opacity: 1;
    transform: none;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideInBottom {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes slideOutBottom {
  from {
    opacity: 1;
    transform: none;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

//** Loader
//========================
@keyframes loaderSpin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

//
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes progress-active {
  0% {
    opacity: 0.1;
    width: 0;
  }

  20% {
    opacity: 0.5;
    width: 0;
  }

  100% {
    opacity: 0;
    width: 100%;
  }
}

@keyframes progress-active-vertical {
  0% {
    opacity: 0.1;
    height: 0;
  }

  20% {
    opacity: 0.5;
    height: 0;
  }

  100% {
    opacity: 0;
    height: 100%;
  }
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@keyframes sideNavFoldedText {
  0% {
    max-width: 200px;
    opacity: 0.8;
  }

  100% {
    max-width: 0;
    opacity: 0;
  }
}

@keyframes placeholder-active {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

//** Carousel
//========================
@keyframes moveLeftHalf {
  0% {
    transform: none;
  }

  100% {
    transform: translateX(-50%);
  }
}

@keyframes moveLeftHalf-vertical {
  0% {
    transform: none;
  }

  100% {
    transform: translateY(-50%);
  }
}
