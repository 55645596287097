@import '../../styles/common';

//
// Panel
// --------------------------------------------------

.@{ns}panel {
  border-radius: @border-radius-base;
  overflow: hidden;

  &-bordered {
    border: @panel-border;
  }

  &-shaded {
    box-shadow: @panel-box-shadow;
  }

  &-heading,
  &-body {
    padding: @panel-padding;
  }

  // When the width is set to full, reset the value of padding.
  &-body-fill {
    padding: 0 !important;
  }

  &-heading {
    color: @panel-heading-color;
    font-size: @panel-heading-font-size;
    line-height: unit(@line-height-computed / @panel-heading-font-size);
  }

  &-title {
    margin: 0;

    a {
      color: @panel-heading-color;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }
  }

  // Adjust the padding value when the head and body are combined.
  &-heading + &-collapse &-body,
  &-heading + &-body {
    padding-top: 10px;
  }

  // Collapsible panel heading hover background
  &-collapsible > &-heading {
    cursor: pointer;
    transition: background-color @panel-accodion-transition,
      border-radius @panel-accodion-transition;
    position: relative;

    &::before {
      position: absolute;
      font-family: @font-family-icon;
      content: @panel-heading-accordion-icon-content;
      top: @panel-padding;
      right: @panel-padding;
      transition: transform @panel-accodion-transition;
    }
  }

  &-in > &-heading::before {
    transform: rotate(180deg);
  }
}
