@import '../../styles/common';
@import './mixin';

// Rate
// ----------------------

.@{ns}rate {
  position: relative;
  display: inline-block;
  color: @rate-selected-color;
  list-style: none;
  margin: 0;
  padding: 0;

  &-character {
    position: relative;
    display: inline-block;
    margin: 3px 10px 3px 0;
    cursor: pointer;
    outline: none;
    user-select: none;

    &-before {
      position: absolute;
      width: 50%;
      height: 100%;
      overflow: hidden;
      opacity: 0;
    }

    &-after {
      color: @rate-default-color;
      filter: grayscale(1);
    }

    &-before,
    &-after {
      display: flex;
      z-index: @zindex-rate-character-before;

      > .@{ns}icon {
        font-size: inherit;
      }
    }

    &-half &-before {
      opacity: 1;
      filter: none;
    }

    &-full &-after {
      color: inherit;
      filter: none;
    }

    &-vertical {
      width: 100%;
      height: 50%;
      flex-direction: column-reverse;
      bottom: 0;
    }
  }

  &:not(&-readonly)&:not(&-disabled) &-character {
    &:hover {
      transform: scale(1.1);
    }

    &:active {
      transform: scale(1);
    }
  }

  &-disabled {
    opacity: 0.3;
  }

  &-disabled &-character {
    cursor: not-allowed;
  }

  &-readonly &-character {
    cursor: default;
    pointer-events: none;
  }
}

.generate-spectrum-rates;
.generate-sizes-rate;
