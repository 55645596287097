@import '../common';

.navbar-default-item-content {
  color: @nav-bar-default-font-color;

  &:hover,
  &:focus,
  &:active {
    color: @B000;
  }
}
