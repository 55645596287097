@import '../../styles/common';
@import '../../Button/styles/mixin';
@import '../../Picker/styles/mixin';

// DatePicker Picker
// ----------------------

// Change the <caret/> & <clean/> vertical position make it align middle.
.@{ns}@{date-picker-prefix},
.@{ns}@{date-range-picker-prefix} {
  &.@{ns}picker-default {
    .@{ns}picker-toggle-caret,
    .@{ns}picker-toggle-clean {
      top: @padding-base-vertical - @picker-default-toggle-border-width;
    }
  }
}

// Toolbar
.@{ns}picker-toolbar {
  .clearfix;

  padding: @calendar-picker-padding;
  border-top: 1px solid @calendar-toolbar-border-color;

  &-ranges {
    display: inline-block;
    margin-left: -@padding-small-horizontal;
    margin-top: 5px;
  }

  &-right {
    float: right;
  }

  &-option {
    .btn(base);
    .btn-sm(base);
    .btn-link-variant(@calendar-option-color);

    padding-top: 0;
    padding-bottom: 0;

    &:not(:last-child) {
      position: relative;

      &::before {
        content: '';
        width: 1px;
        height: 20px;
        background-color: @calendar-toolbar-border-color;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &-disabled {
      opacity: 0.3;
      cursor: not-allowed;

      .reset-link-text-decoration();
    }
  }

  &-right-btn-ok {
    .btn(base);
    .btn-sm(base);
    .button-primary-variant(@calendar-right-btn-ok-color; @calendar-right-btn-ok-bg);
  }

  &-right-btn-ok&-btn-disabled {
    opacity: 0.3;
    cursor: not-allowed;

    &,
    &:hover,
    &:hover:active {
      background-color: @calendar-right-btn-ok-bg;
    }
  }
}

// Picker date
.@{ns}@{date-picker-prefix} {
  .@{ns}picker-toggle-caret::before {
    // Force overwrite icon to calendar icon.
    content: @calendar-default-caret-content !important;
    // Adjust the line-height to make sure icon vertical align middle.
    line-height: 17px;
  }

  &-only-time {
    .@{ns}picker-toggle-caret::before {
      // Force overwrite icon to clock icon.
      content: @calendar-only-time-caret-content !important;
    }
  }

  &-inline {
    height: 299px;

    .@{ns}@{calendar-picker-prefix} {
      height: 286px;
    }
  }
}

// In picker menu
.@{ns}picker-menu {
  .@{ns}picker-toolbar {
    max-width: @calendar-header-width;
  }

  .@{ns}@{calendar-picker-prefix} {
    width: @calendar-header-width;
    // To make sure <Calendar/> horizontal align at center when toolbar  width is so long.
    display: block;
    margin: 0 auto;

    .@{clpns}-month-dropdown-cell-content,
    .@{clpns}-table-cell-content {
      width: @calendar-in-menu-content-side-length;
      height: @calendar-in-menu-content-side-length;
    }

    .@{clpns}-table-header-row .@{clpns}-table-cell-content {
      height: 24px;
      padding-top: 0;
    }

    .@{clpns}-table-cell-content {
      padding-left: 0;
      padding-right: 0;
      display: inline-block;
    }

    .@{clpns}-month-dropdown-scroll {
      height: 227px;
    }

    .@{clpns}-time-dropdown-column > ul {
      height: 214px;
      padding-bottom: 184px;
    }
  }
}
