@import '../../styles/common';
@import '../../Picker/styles/mixin';

// Select Picker
// ----------------------

// Select picker name space
@slns: ~'@{ns}@{select-picker-prefix}';

// Menu Items
.@{slns}-menu-items {
  .picker-menu-items-reset;
  .picker-menu-group-common(@select-picker-prefix);
  .picker-menu-group-title(@select-picker-prefix);
  .picker-menu-group-closed(@select-picker-prefix);
}

// Menu item
.@{slns}-menu-item {
  .picker-select-item;

  &:not(&-disabled):hover,
  &&-focus {
    .picker-item-hover;

    text-decoration: none;
  }

  &&-active {
    .picker-item-active;

    &,
    &:hover {
      color: @picker-select-menu-item-selected-color;
      background-color: @picker-select-menu-item-selected-bg;
    }
  }

  &&-disabled {
    color: @picker-menu-item-disabled-color;
    cursor: @cursor-disabled;
    text-decoration: none;
  }

  &&-active&-disabled {
    &,
    &:hover {
      color: @picker-select-menu-item-selected-disabled-color;
    }
  }

  .grouped &,
  .@{slns}-menu-group-children & {
    padding-left: @picker-group-children-padding-left;
  }
}
