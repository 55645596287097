.default-input() {
  display: block;
  width: 100%;
  color: @input-color;
  background-color: @input-bg;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: @input-border-width solid @input-border;
  transition: @input-transition;
  border-radius: @border-radius-base;
  .input-base;

  // Placeholder
  &::placeholder {
    color: @input-color-placeholder;
  }

  &:focus,
  &:hover {
    border-color: @input-border-focus;
    outline: 0;
  }

  &[disabled] {
    background-color: @input-bg-disabled;
    color: @input-color-disabled;
    cursor: @cursor-disabled;

    &:hover {
      border-color: @input-border;
    }
  }
}

.input-size( @padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius;) {
  @ie-height: @padding-vertical * 2 + @font-size * @line-height;

  padding: (@padding-vertical - @input-border-width) (@padding-horizontal - @input-border-width);
  font-size: @font-size;
  line-height: @line-height;
  .ie-height(@ie-height);

  textarea& {
    height: auto;
  }
}

.input-lg() {
  /* stylelint-disable */ //Formatted by prettier
  .input-size(
    @padding-large-vertical; @padding-base-input-horizontal; @font-size-large; @line-height-large;
    @input-border-radius
  );
  /* stylelint-enable */
}

.input-md() {
  /* stylelint-disable */ //Formatted by prettier
  .input-size(
    @padding-base-vertical; @padding-base-input-horizontal; @font-size-base; @line-height-base;
    @input-border-radius
  );
  /* stylelint-enable */
}

.input-sm() {
  /* stylelint-disable */ //Formatted by prettier
  .input-size(
    @padding-small-vertical; @padding-base-input-horizontal; @font-size-base; @line-height-base;
    @input-border-radius
  );
  /* stylelint-enable */
}

.input-xs() {
  /* stylelint-disable */ //Formatted by prettier
  .input-size(
    @padding-extra-small-vertical; @padding-base-input-horizontal; @font-size-extra-small;
    @line-height-extra-small; @input-border-radius
  );
  /* stylelint-enable */
}

.input-base() {
  .input-md;
}

.reset-input-group-addon-size(@size-name) {
  @padding-horizontal-name: ~'padding-@{size-name}-horizontal';

  @width: @@padding-horizontal-name * 2 - @input-border-width*2 + @font-size-base;
  @padding-horizontal: (@width - @font-size-base)/2;

  width: @width;
  padding-left: @padding-horizontal;
  padding-right: @padding-horizontal;
}

.reset-inside-input-group-btn-size(@size-name) {
  @height: ~'input-height-@{size-name}';
  @vertical: ~'padding-@{size-name}-vertical';
  @horizontal: ~'padding-@{size-name}-horizontal';
  @font-size: ~'font-size-@{size-name}';
  @line-height: ~'line-height-@{size-name}';

  height: @@height - @input-border-width*2;
  padding: (@@vertical - @input-border-width) (@@horizontal - @input-border-width);
  border-radius: 0 @border-radius-base @border-radius-base 0;

  > .@{ns}icon {
    // Extend font-size from parent.
    font-size: @@font-size;
    line-height: @@line-height;
  }
}

.reset-input-group-children-size(@size-name) {
  @height: ~'input-height-@{size-name}';
  @useable-height: @@height - 2px;
  @vertical: ~'padding-@{size-name}-vertical';
  @add-on-padding: ~'input-group-padding-for-add-on-@{size-name}';

  &.@{ns}input-group-inside > .@{ns}input {
    padding-right: @@add-on-padding;
  }

  &.@{ns}input-group:not(.@{ns}input-group-inside) {
    > .@{ns}input {
      height: @useable-height;
    }

    > .@{ns}input-group-addon {
      height: @useable-height;

      > .@{ns}icon {
        font-size: inherit;
      }
    }
  }
}
