.dropdown-toggle-caret-common(@right:@padding-base-horizontal) {
  display: inline-block;
  margin-left: 2px;
  position: absolute;
  top: @padding-base-vertical;
  right: @right;
  font-weight: normal;

  &::before {
    font-family: @dropdown-caret-font-family !important;
  }
}

.dropdown-toggle-caret-size(@vertical,@horizontal) {
  padding-right: @horizontal+ @dropdown-caret-width+ @dropdown-caret-padding !important;

  .@{ns}dropdown-toggle-caret {
    top: @vertical;
    right: @horizontal;
  }
}

.dropdown-toggle-size() {
  &.@{ns}btn-lg {
    .dropdown-toggle-caret-size(@padding-large-vertical, @padding-large-horizontal);
  }

  &.@{ns}btn-sm {
    .dropdown-toggle-caret-size(@padding-small-vertical, @padding-small-horizontal);
  }

  &.@{ns}btn-xs {
    .dropdown-toggle-caret-size(@padding-extra-small-vertical, @padding-extra-small-horizontal);
  }
}

.set-dropdown-caret(@prefix) {
  .@{prefix}-placement-left-end &::before,
  .@{prefix}-placement-left-start &::before {
    content: @drop-left-caret-content ~'/* rtl: "@{drop-right-caret-content}" */';
  }

  .@{prefix}-placement-right-end &::before,
  .@{prefix}-placement-right-start &::before {
    content: @drop-right-caret-content ~'/* rtl: "@{drop-left-caret-content}" */';
  }

  .@{prefix}-placement-top-start &::before,
  .@{prefix}-placement-top-end &::before {
    content: @dropup-caret-content;
  }

  .@{prefix}-placement-bottom-start &::before,
  .@{prefix}-placement-bottom-end &::before {
    content: @dropdown-caret-content;
  }
}

.dropdown-toggle() {
  position: relative;
  z-index: @zindex-dropdown;
  padding-right: @dropdown-toggle-padding-right;
  // Fixed: Content is not centered when customizing renderTitle.
  display: inline-block;
  // Rest `:focus` blue border.
  outline: none;
  cursor: pointer;

  // Custom title toggle need reset padding.
  &-custom-title {
    padding: 0 !important;
  }
}

// Horizontal dividers
//
// Dividers (basically an hr) within dropdowns and nav lists

.nav-divider(@color: #e5e5e5) {
  height: 1px;
  margin: @nav-divider-margin-vertical 0;
  overflow: hidden;
  background-color: @color;
}
