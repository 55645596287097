@import '../../styles/common';
@import '../../Form/styles/index';

//
// FormGroup
// --------------------------------------------------

.@{ns}form-group {
  .@{ns}form:not(.@{ns}form-inline) &:not(:last-child) {
    margin-bottom: @form-group-margin-bottom;
  }

  .@{ns}input {
    display: inline-block;
  }

  // Fixed inline element will produce a whitespace
  textarea.@{ns}input {
    vertical-align: bottom;
  }
}

// Vertical forms
.@{ns}form-vertical .@{ns}form-group {
  .@{ns}control-label {
    display: block;
  }

  .@{ns}help-block:not(.@{ns}help-block-tooltip) {
    padding-top: 4px;
  }
}

// Horizontal forms
//
// Horizontal forms are built on grid classes and allow you to create forms with
// labels on the left and inputs on the right.

.@{ns}form-horizontal .@{ns}form-group {
  .clearfix;

  .@{ns}control-label {
    float: left;
    display: inline-block;
    //Sometimes help info is more than one line，so height can't set a fixed value
    min-height: @line-height-computed;
    line-height: @line-height-base;
    font-size: @font-size-base;
    padding: @padding-base-vertical 0;
    width: @control-label-width;
    margin-right: @control-label-margin-right;
    text-align: right;
  }

  .@{ns}btn-toolbar {
    padding-left: @control-label-width + @control-label-margin-right;
  }
}

// Inline
.@{ns}form-inline .@{ns}form-group {
  display: inline-block;
}
