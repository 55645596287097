@import '../../styles/common';

// Help text
//
// Apply to any element you wish to create light text for placement immediately
// below a form control. Use for general help, formatting, or instructional text.

.@{ns}help-block {
  display: block; // account for any element using help-block
  color: @help-block-color; // lighten the text some for contrast
  //Sometimes help info is more than one line，so height can't set a fixed value
  min-height: @line-height-computed;
  line-height: @line-height-base;
  font-size: @font-size-base;

  &-tooltip {
    display: inline-block;
    vertical-align: top;
    margin-left: @help-block-inline-gap;
    margin-top: @padding-base-vertical;

    .@{ns}icon::before {
      .icon-font;

      content: @icon-help-o;
    }
  }
}
