@import '../../styles/common';

//
// Tooltips
// --------------------------------------------------

// Base class
.@{ns}tooltip {
  position: absolute;
  z-index: @zindex-tooltip;
  display: block;
  font-size: @font-size-small;
  opacity: 0;
  line-height: @line-height-small;

  &.fade {
    transition: opacity 0.1s linear, transform 0.1s ease-out;
  }

  &.in {
    opacity: 1;
    transition: opacity 0.15s linear, transform 0.15s ease-in;
  }

  // Wrapper for the tooltip content
  .@{ns}tooltip-inner {
    max-width: @tooltip-max-width;
    padding: 2px 10px;
    color: @tooltip-color;
    text-align: center;
    background-color: @tooltip-bg;
    border-radius: @tooltip-border-radius;
    overflow-wrap: break-word;
  }

  // Arrows
  .@{ns}tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
}

// Common style for .placement-top*
.@{ns}tooltip[class*='placement-top'] {
  margin-top: -@tooltip-arrow-width;
  .set-translate-transition(0, -@tooltip-translate-distance);

  .@{ns}tooltip-arrow {
    bottom: -@tooltip-arrow-width;
    // FIXED: The problem of the  1 pixel white border in Edge/IE11 browser .
    .edge-position(bottom, -@tooltip-arrow-width+1px);
    .ie11-position(bottom, -@tooltip-arrow-width+1px);

    margin-left: -@tooltip-arrow-width;
    border-width: @tooltip-arrow-width @tooltip-arrow-width 0;
    border-top-color: @tooltip-arrow-color;
  }
}

// Common style for .placement-bottom*
.@{ns}tooltip[class*='placement-bottom'] {
  margin-top: @tooltip-arrow-width;
  .set-translate-transition(0, @tooltip-translate-distance);

  .@{ns}tooltip-arrow {
    top: -@tooltip-arrow-width;
    // FIXED: The problem of the  1 pixel white border in Edge/IE11 browser .
    .edge-position(top, -@tooltip-arrow-width+1px);
    .ie11-position(top, -@tooltip-arrow-width+1px);

    margin-left: -@tooltip-arrow-width;
    border-width: 0 @tooltip-arrow-width @tooltip-arrow-width;
    border-bottom-color: @tooltip-arrow-color;
  }
}

/* rtl:begin:ignore */
// Common style for .placement-right*
.@{ns}tooltip[class*='placement-right'] {
  margin-left: @tooltip-arrow-width;
  .set-translate-transition(@tooltip-translate-distance, 0);

  .@{ns}tooltip-arrow {
    left: -@tooltip-arrow-width;
    // FIXED: The problem of the  1 pixel white border in Edge/IE11 browser .
    .edge-position(left, -@tooltip-arrow-width+1px);
    .ie11-position(left, -@tooltip-arrow-width+1px);

    margin-top: -@tooltip-arrow-width;
    border-width: @tooltip-arrow-width @tooltip-arrow-width @tooltip-arrow-width 0;
    border-right-color: @tooltip-arrow-color;
  }
}

// Common style for .placement-lef*
.@{ns}tooltip[class*='placement-left'] {
  margin-left: -@tooltip-arrow-width;
  .set-translate-transition(-@tooltip-translate-distance, 0);

  .@{ns}tooltip-arrow {
    right: -@tooltip-arrow-width;
    // FIXED: The problem of the  1 pixel white border in Edge/IE11 browser .
    .edge-position(right, -@tooltip-arrow-width+1px);
    .ie11-position(right, -@tooltip-arrow-width+1px);

    margin-top: -@tooltip-arrow-width;
    border-width: @tooltip-arrow-width 0 @tooltip-arrow-width @tooltip-arrow-width;
    border-left-color: @tooltip-arrow-color;
  }
}

/* rtl:end:ignore */

.@{ns}tooltip.placement-bottom,
.@{ns}tooltip.placement-top {
  .@{ns}tooltip-arrow {
    left: 50%;
  }
}

.@{ns}tooltip.placement-bottom-start,
.@{ns}tooltip.placement-top-start {
  .@{ns}tooltip-arrow {
    left: @tooltip-arrow-gap-lg;
  }
}

.@{ns}tooltip.placement-bottom-end,
.@{ns}tooltip.placement-top-end {
  .@{ns}tooltip-arrow {
    right: @tooltip-arrow-gap-sm;
  }
}

.@{ns}tooltip.placement-right,
.@{ns}tooltip.placement-left {
  .@{ns}tooltip-arrow {
    top: 50%;
  }
}

.@{ns}tooltip.placement-right-start,
.@{ns}tooltip.placement-left-start {
  .@{ns}tooltip-arrow {
    top: @tooltip-arrow-gap-lg;
  }
}

.@{ns}tooltip.placement-right-end,
.@{ns}tooltip.placement-left-end {
  .@{ns}tooltip-arrow {
    bottom: @tooltip-arrow-gap-sm;
  }
}
