@import '../../styles/common';
@import '../../Form/styles/mixin';

//
// Forms
// --------------------------------------------------

// Common form controls
.@{ns}input {
  .default-input;

  // Reset height for `textarea`
  textarea& {
    resize: none;
    // Set textarea valid size
    min-width: 300px;
    min-height: 76px;
    max-width: 100%;
    max-height: 200px;
  }
}

// Form control sizing
.@{ns}input-xs {
  .input-xs;
}

.@{ns}input-lg {
  .input-lg;
}

.@{ns}input-sm {
  .input-sm;
}
