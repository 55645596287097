@import '../../styles/common';

//
// Placeholder
// --------------------------------------------------

.@{ns}placeholder {
  display: flex;

  &-active {
    &.@{ns}placeholder-graph,
    .@{ns}placeholder-paragraph-graph-image,
    .@{ns}placeholder-grid-col > p,
      //noinspection Stylelint
    .@{ns}placeholder-paragraph-rows > p {
      background: linear-gradient(
        -45deg,
        @placeholder-holder-bg 25%,
        @placeholder-holder-bg-active 37%,
        @placeholder-holder-bg 63%
      );
      background-size: 400% 100% !important;
      animation: placeholder-active 3s ease infinite;
    }
  }

  &-paragraph {
    justify-content: flex-start;
    align-items: flex-start;

    &-graph {
      flex: 0 0 auto;
      width: 60px;
      height: 60px;
      border-radius: 2px;
      margin-right: 20px;
      background-color: @placeholder-holder-bg;

      &-circle {
        border-radius: 50%;
      }

      &-image {
        width: 80px;
        height: 60px;
        position: relative;
        overflow: hidden;

        &::after {
          content: '';
          width: 0;
          height: 0;
          left: 10px;
          bottom: 10px;
          position: absolute;
          border-bottom: 36px solid @placeholder-image-color;
          border-left: 20px solid transparent;
          border-right: 30px solid transparent;
        }

        &::before {
          content: '';
          width: 0;
          height: 0;
          left: 40px;
          bottom: 10px;
          position: absolute;
          border-bottom: 22px solid @placeholder-image-color;
          border-left: 16px solid transparent;
          border-right: 16px solid transparent;
        }
      }

      &-image &-inner {
        width: 12px;
        height: 12px;
        right: 18px;
        top: 10px;
        border-radius: 50%;
        background: @placeholder-image-color;
        position: absolute;
      }
    }

    &-rows {
      width: 100%;

      & > p {
        background-color: @placeholder-holder-bg;
      }
    }
  }

  &-grid {
    &-col {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      & > p {
        background-color: @placeholder-holder-bg;
      }

      &:first-child {
        align-items: flex-start; //first col align left
      }
    }
  }

  &-graph {
    display: inline-block;
    width: 100%;
    background-color: @placeholder-holder-bg;
  }
}
