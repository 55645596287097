@import '../../styles/common';
@import '../../Picker/styles/mixin';

.@{ns}auto-complete {
  &-menu > {
    // Auto complete items style the same to Picker menu items
    .picker-menu-items-reset;

    margin-top: @border-radius-base;
  }

  // The same to Picker select menu item
  &-item {
    .picker-select-item;

    &,
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    &-focus,
    &:hover {
      .picker-item-hover;
    }
  }

  .@{ns}input:focus::-webkit-textfield-decoration-container {
    visibility: hidden;
    pointer-events: none;
  }
}
