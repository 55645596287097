@import '../../styles/common';
@import 'mixin';

.@{ns}avatar {
  background-color: @avatar-background;
  color: @avatar-content-color;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: @avatar-border-radius;
  overflow: hidden;
  .avatar-md;

  & > .@{ns}icon {
    vertical-align: middle;
  }

  &-image {
    position: relative;
  }

  &-image::before {
    content: attr(alt);
    position: absolute;
    width: 100%;
    height: inherit;
    top: 0;
    left: 0;
    background: @avatar-background;
    text-align: center;
    padding: 0 2px;
    .ellipsis-basic;
  }

  &-circle {
    border-radius: 50%;
  }

  &-lg {
    .avatar-lg;
  }

  &-sm {
    .avatar-sm;
  }

  &-xs {
    .avatar-xs;
  }
}
