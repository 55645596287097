@import '../../styles/common';
@import '../../Button/styles/mixin';
@import 'mixin';

//
// Pickers
// --------------------------------------------------
.@{ns}btn-toggle {
  // Default size is middle.
  .btn-toggle(md);

  position: relative;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  background-color: @toggle-default-bg;
  cursor: pointer;
  transition: background-color @toggle-transition, width @toggle-transition;
  user-select: none;

  &:hover {
    background-color: @toggle-default-hover-bg;
  }

  &::after {
    content: '';
    cursor: pointer;
    position: absolute;
    background-color: @toggle-md-handle-bg;
    transition: left @toggle-transition, margin-left @toggle-transition, width @toggle-transition;
  }

  &-inner {
    color: @toggle-inner-text-color;
    display: block;
    transition: margin @toggle-transition;
  }

  &-checked {
    background-color: @toggle-checked-bg;

    &:hover {
      background-color: @toggle-checked-hover-bg;
    }
  }

  &-disabled {
    &,
    &::after {
      cursor: not-allowed;
    }

    &,
    &:hover {
      background-color: @toggle-disabled-default-background-color;
    }
  }

  &-disabled&-checked {
    &,
    &:hover {
      background-color: @toggle-disabled-checked-background-color;
    }
  }
}

// small
.@{ns}btn-toggle-sm {
  .btn-toggle(sm);
}

// middle
.@{ns}btn-toggle-md {
  .btn-toggle(md);
}

// large
.@{ns}btn-toggle-lg {
  .btn-toggle(lg);
}
